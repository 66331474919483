import {
  Container,
  DigilinklabsIcon,
  Form,
  FieldList,
  Button,
  Flex,
  Text,
  request,
  LocalStorageService,
  RichText,
  Seo,
} from '@digilinklabs/components';
import { APP_FORMS } from '@constants';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useRouter } from 'next/router';
import Image from 'next/image';
const imageKitLoader = ({ src, width, quality }) => {
  if (src[0] === '/') src = src.slice(1);
  const params = [`w-${width}`];
  if (quality) {
    params.push(`q-${quality}`);
  }
  const paramsString = params.join(',');
  let urlEndpoint = 'https://ik.imagekit.io/uqv5qo2qiyv';
  if (urlEndpoint[urlEndpoint.length - 1] === '/')
    urlEndpoint = urlEndpoint.substring(0, urlEndpoint.length - 1);
  return `${urlEndpoint}/${src}?tr=${paramsString}`;
};
const LoginView = () => {
  const [errors, setErrors] = useState([]);
  const router = useRouter();
  const { mutateAsync, isLoading, error, isError } = useMutation((data) => {
    return request.post('auth/login', data);
  });
  const handleSave = async (data) => {
    const { data: response } = await mutateAsync(data);
    LocalStorageService.setToken(response?.tokens);
    await router.replace('/overview');
  };
  useEffect(() => {
    if (isError) {
      setErrors([
        {
          type: 'custom',
          message: 'Invalid password',
          target: 'password',
        },
        {
          type: 'custom',
          message: 'Invalid email',
          target: 'email',
        },
      ]);
    }
  }, [error, isError]);

  return (
    <Container padding="0" flexDirection="row">
      <Seo {...APP_FORMS.login.seo} />
      <Container padding="0" flex="1" position="relative">
        <Image
          loader={imageKitLoader}
          src="images/digilinklabs-natasa-grabovac.jpg"
          alt="jay-mullings-L6a3yenfaF0-unsplash"
          layout="fill"
          width={500}
          height={800}
          quality={10}
          priority
        />
      </Container>
      <Container flex="1.3" padding="0">
        <Container padding="0" justifyContent="flex-end" alignItems="center">
          <DigilinklabsIcon size={142} />
        </Container>
        <Container padding="0 50px" justifyContent="center" alignItems="center" flex="2">
          <Flex justifyContent="center" alignItems="center" width="100%" flex="0.5">
            <Text Tag="h1" type="h">
              {APP_FORMS.login.formName}
            </Text>
          </Flex>
          <Flex
            width="75%"
            justifyContent="flex-start"
            alignSelf="center"
            flex="2"
            flexDirection="column"
          >
            <Form onSubmit={handleSave} className="form" errors={errors}>
              <FieldList fields={APP_FORMS.login.fields} />
              <Flex justifyContent="space-between" margin="24px 0 0 0" alignItems="center">
                <RichText className="forgot-password">
                  {APP_FORMS.login.action.forgotPassword.markupText}
                </RichText>
                <Button className="buttonCTA" size="medium" disabled={isLoading}>
                  {APP_FORMS.login.ctaName}
                </Button>
              </Flex>
            </Form>
            {/*<Flex alignSelf="center" margin="50px 0 0 0">
              <RichText className="forgot-password">
                {APP_FORMS.login.action.register.markupText}
              </RichText>
            </Flex>*/}
          </Flex>
        </Container>
      </Container>
    </Container>
  );
};

export default LoginView;
